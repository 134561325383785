#activity-detail {
  #details-section {
    .inline-block {
      max-width: 90%;
      display: inline-block;
      vertical-align: top;
    }
  }
}


.back-to-activities-button {
  cursor: pointer;
}