@charset 'utf-8';

/* Core branding API */
@import '~@a-ui/core/dist/assets/styles/quarks';

body {
  display: flex;
  min-height: 100vh;
}

#root {
  display: flex;
  width: 100%;
}

.mobile-only {
  @media only screen and (min-width: 45em) {
    display: none;
  }
}

.tablet-only {
  @media only screen and (max-width: 45em) {
    display: none;
  }
}

.grid-box {
  padding-top: $spacer;
  padding-left: $spacer;
  padding-right: $spacer;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.input-box {
  padding-bottom: $spacer-xs;
  width: 100%;
}
