@import "../../../../node_modules/@a-ui/core/dist/assets/styles/quarks";

.activity-result-item {
  .a-list {
    .inline-block {
      max-width: 90%;
      display: inline-block;
      vertical-align: top;
    }
  }

  .m-flyout__content {
    width: max-content;
  }
}

.pagination-box {
  display: flex;
  justify-content: center;
  margin-top: $spacer;
}

.pagination-button {
  padding: 0 !important;
  line-height: auto;
  width: 100%;
}

.activity-image {
  background-position: center;
  background-size: cover;
  min-height: 200px;
}

.activity-map-image {
  background-position: center;
  background-size: cover;
  padding: 0;
  min-height: 150px;
}

.activity-image-wrapper {
  flex-direction: column;
  display: flex;
  align-items: stretch;

  .activity-image {
    flex-grow: 1;
  }

  .activity-counter {
    text-align: center;
    padding: $spacer / 2;
  }
}

.dates-icon {
  cursor: pointer;
  color:#0064b4;
}

.detail-line {
  position: relative;
}

.dateline {
  padding: 0.5rem 1rem 0 1rem;
}
.dateline:last-child {
  padding-bottom: 0.5rem;
}