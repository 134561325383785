.astad-login-popup {
  .required-login {
    padding: 1.5rem 5rem 5rem 5rem;
  }

  .allow-anonymous {
    padding: 4rem 5rem 1rem 5rem;
  }

  .m-overlay__inner {
    position: relative;
    background-color: white;
  }

  .m-modal__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #0064b4;
    color: white;
    padding: 1rem 1.5rem;

    .btn-close {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .m-modal-anonymous, .m-modal-aprofile {
    button {
      width: 100%;
    }
  }

  .m-modal-aprofile {
    border-top: 1px solid #BABABA;
    background: #F3F3F5;
    margin-bottom: -5rem;

    button {
      margin-top: 1.5rem;
    }
  }

  .m-modal__content {
    margin-left: -5rem;
    margin-right: -5rem;
    padding: .75rem 1.5rem;

  }

  .m-modal__body {
    #btn-no-login {
      width: 100%;
    }
  }

  .m-modal {
    border: none !important;
}
}