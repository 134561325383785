.sportstad-header  {
  height: 15rem;
  //background-color: black;
  color: white;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
}

.sportstad-header-container {
  position: relative;
}

.sportstad-header-blurred-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  border-bottom: 10px solid white;
}

.sportstad-header {
  display: flex;
  background-size: contain;
  align-items: center;
}

.sportstad-header-wrapper {
  width: 100%;

  h1 {
    text-shadow: #000 1px 0 3px;
  }
}

.sportstad-header-cta {
  width: auto;
  right:0;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  flex-direction: row;

  color: black;
  .sportstad-header-cta__content {
    background-color: white;
    padding: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
}