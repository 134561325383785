@import '../../../../node_modules/@a-ui/core/dist/assets/styles/quarks';

.m-cart-list {
  &__loading {
    padding: $spacer-xl;
  }

  &__total {
    display: flex;
    padding: 0 $spacer;
  }

  &__pay {
    display: flex;
    justify-content: flex-end;
  }
}
