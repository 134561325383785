@charset 'utf-8';

/* Core branding API */
@import '~@a-ui/core/dist/assets/styles/quarks';

.App {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  max-width: 100%;

  .o-header {
    z-index: layer('top');

    img {
      height: 4.5rem;
      margin: 1rem;

      @media screen and (min-width: 62rem) {
        height: 8rem;
      }
    }
  }
}

#astad-user-menu .m-nav-list  {
  li:not(.show) {
  display: none;
  }
}

.pagination-button{
  cursor: pointer;
}

.main {
  flex: 1 0 auto;
  width: 100%;

  @media screen and (min-width: $screen-md-max) {
    min-height: 100vh;
  }

  @include to($screen-xs-max) {
    padding-left: 0;
  }
}

.m-button-group {
  float: right;
}

.u-align-center{
  display: flex;
  align-items: center;
  justify-content: center;
}
