.m-cart-button {
    position: relative;
    justify-self: flex-end;

  &__badge {
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
  }
}
