.postal-code-picker {
  width: 100%;
}

.result-row-postal-code-picker {
  width: 100%;
  padding: 1rem;
  cursor: pointer;
  border-top: 1px solid #DDD;
}

.result-row-postal-code-picker:first-child {
  border-top: 0;
}

.result-row-postal-code-picker:hover {
  background-color: #DDD;
}