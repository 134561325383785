@import '~@a-ui/core/dist/assets/styles/quarks';

.inline-switch{
    .a-input{
        display: inline-flex;
        align-items: center;
        &__label{
            font-weight: bold;
            margin-bottom: 0;
            margin-right: $spacer;
        }

        .a-switch{
            margin-top: 0
        }
    }
}

.p-confirmation-row {
    display: flex;
    flex-direction: row;
}