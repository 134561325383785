.back-to-activities-button {
  .back-link {
    color: black;
    margin: 0 0.75rem 0 0.75rem;
  }
  .fa {
    font-size: 1.125rem;
    height: 2.4rem;
    width: 2.4rem;
    text-align: center;
    line-height: 2.65rem;
    color: #fff;
    background: #0064b4;
  }
}

.row-space-between {
  display: flex;
  justify-content: space-between;
}
