#activities-basic-map-container {
  .mapMenuContainer {
	width: 33.33%;
	min-width: 23.75rem;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    .mapMenuContainer {
      width: 40%;
	  min-width: 250px;
	  &:not(.collapsed) .mapMenuControl {
		  left: 0;
		  z-index: 9999;
	  }
    }
  }

  .mapMenuContent {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    overflow: scroll;
    z-index: 1001;

    .inline-block {
      display: inline-block;
      vertical-align: top;
      width: 80%;
    }

    img {
      width: 100%;
    }

  }
}