@import '../../../../node_modules/@a-ui/core/dist/assets/styles/quarks';

.m-cart-list-item {
  padding: $spacer;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: $screen-xs) {
    display: flex;
    flex-direction: column;

    .col-xs-1 {
      flex-basis: none !important;
      max-width: none !important;
      width: 100% !important;
      margin: $spacer-xs;

      .a-button {
        width: 100% !important;
      }
    }
  }

  &__title {
    color: $endeavour-400;
  }

  &__button {
    height: 3rem;
    align-self: center;
  }

  &__content {
    display: flex;
    width: 100%;
    flex-direction: column;
    @media only screen and (max-width: $screen-xs) {
      justify-content: center;
    }
  }

  &__row {
    display: flex;
  }

  &__price-mobile {
    display: none;
    @media only screen and (max-width: $screen-xs) {
      display: inline-flex;
      align-items: center;
      font-weight: bold;
      p {
        font-size: 1.1rem;
      }
    }
  }

  &__price {
    display: flex;
    align-items: center;
    margin: 0 $spacer-xs;
    justify-content: flex-end;
    min-width: 75px;

    @media only screen and (max-width: $screen-xs) {
      display: none;
    }

    p {
      // font-size: 1.2rem;
      font-weight: 500;
      font-family: Antwerpen Regular;
      line-height: 1.1;
    }
  }

  &__subtitle{
    font-weight: 500;
    font-family: Antwerpen Regular;
    line-height: 1.1;
  }

  &__coupon {
    display: flex;

    .a-input {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__label {
        font-weight: bold;
        text-align: center;
        margin-bottom: 0;
      }
    }

    @media only screen and (max-width: $screen-xs) {
      display: none;
    }
  }

  &__coupon-mobile {
    display: none;
    @media only screen and (max-width: $screen-xs) {
      display: flex;

      .a-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .a-switch {
          margin-top: 0 !important;
        }

        &__label {
          margin-right: $spacer-xs;
          font-weight: bold;
          text-align: center;
          margin-bottom: 0;
        }
      }
    }
  }

  &__total {
    text-align: flex-end;
  }

  &__total-price {
    display: flex;
    align-items: center;
    margin: 0 $spacer-xs;
    justify-content: flex-end;
    min-width: 75px;

    p {
      font-size: 1.2rem;
      font-weight: 500;
      font-family: Antwerpen Regular;
      line-height: 1.1;
    }
  }

  .a-button.has-icon .fa {
    position: initial;
  }
}
